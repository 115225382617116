<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(role ? onUpdate : onCreate)">
      <!-- Назва -->
      <ValidationProvider
        name="назва"
        rules="required|max:255"
        v-slot="{ errors }"
      >
        <b-form-group
          label-class="font-weight-bold"
          label="Назва:"
          label-cols="4"
          :horizontal="true"
        >
          <b-form-input
            type="text"
            v-model="form.name"
            :state="errors.length > 0 ? false : null"
            :disabled="role !== null"
          />
          <b-form-invalid-feedback
            :state="errors.length === 0"
            v-for="(error, index) in errors"
            v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>
      <!-- END: Назва -->

      <!-- Дозволи -->
      <b-form-group
        label-class="font-weight-bold"
        label="Дозволи:"
        label-cols="4"
        :horizontal="true"
      >
        <b-form-checkbox
          v-for="permission in permissions"
          v-model="form.permissions"
          :key="permission.id"
          :value="permission.id"
        >
          {{ permissionMapper(permission.name) }}
        </b-form-checkbox>
      </b-form-group>
      <!-- END: Дозволи -->

      <div slot="footer">
        <b-button
          type="submit"
          size="sm"
          :variant="role !== null ? 'primary' : 'success'"
        >
          <i class="fa fa-dot-circle-o"></i>
          {{ role !== null ? "Зберегти" : "Додати" }}
        </b-button>
      </div>
    </b-form>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from "vuex";
import mapper from "@/entity/Users/mixins/mapper";

export default {
  name: "RoleForm",
  props: {
    role: {
      type: Object,
      default() {
        return null;
      }
    }
  },
  mixins: [mapper],
  created() {
    if (this.role) {
      this.form.name = this.role.name;
      this.form.permissions = this.role.permissions.map(
        permission => permission.id
      );
    }
  },
  data() {
    return {
      form: {
        name: "",
        permissions: []
      }
    };
  },
  computed: {
    ...mapGetters({
      roles: "user/roles",
      permissions: "user/permissions"
    })
  },
  methods: {
    onCreate() {
      this.$store.dispatch("user/createRole", this.form);
      this.$root.$emit("bv::hide::modal", "modalRole");
    },
    onUpdate() {
      const form = { ...this.form };
      delete form.name;
      form.id = this.role.id;
      this.$store.dispatch("user/updateRole", form);
      this.$root.$emit("bv::hide::modal", "modalRole");
    }
  }
};
</script>
