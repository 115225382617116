<template>
  <div>
    <b-row v-if="$auth.can('create user')">
      <b-col cols="auto" class="mr-auto p-3">
        <b-button
          variant="success"
          size="sm"
          @click="createUser()"
          class="mb-2"
        >
          <i class="fa fa-dot-circle-o"></i> Додати користувача
        </b-button>
      </b-col>
    </b-row>

    <b-table
      :dark="false"
      :fields="fields"
      :hover="true"
      :striped="true"
      :bordered="true"
      :small="false"
      :fixed="true"
      responsive="sm"
      :items="users"
    >
      <template v-slot:cell(permissions)="data">
        <ul>
          <li v-for="permission in data.item.permissions" :key="permission.id">
            {{ permissionMapper(permission.name) }}
          </li>
        </ul>
      </template>

      <template v-slot:cell(financial_models)="data">
        <ul>
          <li
            v-for="financial_model in data.item.financial_models"
            :key="financial_model.id"
          >
            {{ financial_model.name }}
          </li>
        </ul>
      </template>

      <template v-slot:cell(actions)="data">
        <b-button
          variant="ghost-info link"
          size="sm"
          @click="editUser(data.index)"
        >
          <i class="fa fa-edit"></i>
        </b-button>

        <b-button
          v-if="$store.getters['auth/me'].id !== data.item.id"
          variant="ghost-danger"
          size="sm"
          @click="deleteUser(data.index)"
        >
          <i class="fa fa-close"></i>
        </b-button>
      </template>
    </b-table>

    <b-modal id="modalUser" title="Форма користувача" hide-footer size="lg">
      <user-form :prop-user="user" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UserForm from "./forms/UserForm";
import mapper from "./mixins/mapper";

export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.$auth.can("view user")) next();
      else next({ name: "Page404" });
    });
  },
  name: "Users",
  mixins: [mapper],
  components: {
    UserForm
  },
  created() {
    this.$store.dispatch("user/pull");
    this.$store.dispatch("user/pullRoles");
  },
  computed: {
    ...mapGetters({
      users: "user/users"
    })
  },
  data() {
    return {
      user: null,
      fields: [
        {
          key: "email",
          label: "Електронна пошта"
        },
        {
          key: "role",
          label: "Роль",
          formatter: role => this.roleMapper(role.name)
        },
        {
          key: "permissions",
          label: "Права"
        },
        {
          key: "financial_models",
          label: "Профілі СПФМ"
        },
        {
          key: "permissions",
          label: "Профілі СПФМ"
        },
        {
          key: "actions",
          label: "Дії"
        }
      ]
    };
  },
  methods: {
    createUser() {
      this.user = null;
      this.$root.$emit("bv::show::modal", "modalUser");
    },
    editUser(index) {
      this.user = this.users[index];
      this.$root.$emit("bv::show::modal", "modalUser");
    },
    deleteUser(index) {
      if (this.users[index].id) {
        if (confirm("Ви впевнені що хочете видалити цього користувача?"))
          this.$store.dispatch("user/delete", this.users[index]);
      }
    }
  }
};
</script>
