<template>
  <b-card>
    <b-tabs content-class="mt-3">
      <b-tab title="Користувачі" active>
        <user-list />
      </b-tab>

      <b-tab title="Ролі та дозволи">
        <role-permission-list />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import UserList from "./UserList";
import RolePermissionList from "./RolePermissionList";
export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.$auth.can("view user")) next();
      else next({ name: "Page404" });
    });
  },
  name: "UserTabs",
  components: {
    UserList,
    RolePermissionList
  }
};
</script>

<style scoped></style>
