<template>
  <div>
    <b-row v-if="$auth.can('create user')">
      <b-col cols="auto" class="mr-auto p-3">
        <b-button
          variant="success"
          size="sm"
          @click="createRole()"
          class="mb-2"
        >
          <i class="fa fa-dot-circle-o"></i> Додати роль
        </b-button>
      </b-col>
    </b-row>

    <b-table
      :dark="false"
      :fields="fields"
      :hover="true"
      :striped="true"
      :bordered="true"
      :small="false"
      :fixed="true"
      responsive="sm"
      :items="roles"
    >
      <template v-slot:cell(permissions)="data">
        <ul>
          <li v-for="permission in data.item.permissions" :key="permission.id">
            {{ permissionMapper(permission.name) }}
          </li>
        </ul>
      </template>

      <template v-slot:cell(actions)="data">
        <b-button
          v-if="!data.item.is_system"
          variant="ghost-info link"
          size="sm"
          @click="editRole(data.item)"
        >
          <i class="fa fa-edit"></i>
        </b-button>

        <b-button
          v-if="!data.item.is_system"
          variant="ghost-danger"
          size="sm"
          @click="deleteRole(data.item)"
        >
          <i class="fa fa-close"></i>
        </b-button>
      </template>
    </b-table>

    <b-modal id="modalRole" title="Форма ролі" hide-footer size="lg">
      <role-form :role="roleForEditing" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import mapper from "@/entity/Users/mixins/mapper";
import RoleForm from "@/entity/Users/forms/RoleForm.vue";

export default {
  name: "RolePermissionList",
  mixins: [mapper],
  components: {
    RoleForm
  },
  created() {
    this.$store.dispatch("user/pullPermissions");
  },
  data() {
    return {
      roleForEditing: null,
      fields: [
        {
          key: "name",
          label: "Роль",
          formatter: name => this.roleMapper(name)
        },
        {
          key: "permissions",
          label: "Права"
        },
        {
          key: "actions",
          label: "Дії"
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      roles: "user/roles"
    })
  },
  methods: {
    createRole() {
      this.roleForEditing = null;
      this.$root.$emit("bv::show::modal", "modalRole");
    },
    editRole(role) {
      this.roleForEditing = role;
      this.$root.$emit("bv::show::modal", "modalRole");
    },
    deleteRole(role) {
      if (confirm("Ви впевнені що хочете видалити цю роль?"))
        this.$store.dispatch("user/deleteRole", role);
    }
  }
};
</script>

<style scoped></style>
