<template>
  <ValidationObserver ref="formValidationUser" v-slot="{ handleSubmit }">
    <b-alert :show="Object.keys(errors).length > 0" variant="danger">
      <h4 class="alert-heading">
        Виникла помилка при збереженні користувача!
      </h4>
      <li v-for="(error, index) in errors" :key="index">
        <span v-for="(e, i) in error" :key="i">
          {{ e }}
        </span>
      </li>
    </b-alert>
    <b-form @submit.prevent="handleSubmit(onSubmit)">
      <b-form-group label-size="lg">
        <ValidationProvider
          name="адреса електронної пошти"
          rules="required|email"
          v-slot="{ errors, dirty, validated, valid, changed }"
          vid="email"
        >
          <b-form-group
            label-class="font-weight-bold"
            label="Адреса електронної пошти:"
            label-cols="4"
            :horizontal="true"
          >
            <b-form-input
              type="email"
              v-model="form.email"
              :state="
                setValidationState(errors, dirty, validated, valid, changed)
              "
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="номер телефону"
          rules="length:13"
          v-slot="{ errors, dirty, validated, valid, changed }"
          vid="phone"
        >
          <b-form-group
            label-class="font-weight-bold"
            label="Номер телефону:"
            label-cols="4"
            :horizontal="true"
          >
            <b-form-input
              type="text"
              v-model="form.phone"
              :state="
                setValidationState(errors, dirty, validated, valid, changed)
              "
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="роль"
          rules="required"
          v-slot="{ errors, dirty, validated, valid, changed }"
          vid="role_id"
        >
          <b-form-group
            label-class="font-weight-bold"
            label="Роль:"
            label-cols="4"
            :horizontal="true"
          >
            <b-select
              v-model="form.role_id"
              :state="
                setValidationState(errors, dirty, validated, valid, changed)
              "
            >
              <option v-for="role in roles" :value="role.id" :key="role.id">{{
                roleMapper(role.name)
              }}</option>
            </b-select>
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="Профіль СПФМ"
          v-slot="{ errors, dirty, validated, valid, changed }"
          vid="financial_models"
        >
          <b-form-group
            label-class="font-weight-bold"
            label="Профілі СПФМ:"
            label-cols="4"
            :horizontal="true"
          >
            <b-form-checkbox-group
              v-model="form.financial_models"
              :options="financialModels"
              value-field="id"
              text-field="name"
              stacked
              :state="
                setValidationState(errors, dirty, validated, valid, changed)
              "
            >
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-checkbox-group>
          </b-form-group>
        </ValidationProvider>
      </b-form-group>

      <div slot="footer">
        <b-button
          type="submit"
          size="sm"
          :disabled="submitting"
          :variant="propUser !== null ? 'primary' : 'success'"
        >
          <i class="fa fa-dot-circle-o"></i>
          {{ propUser !== null ? "Зберегти" : "Додати" }}
        </b-button>
      </div>
    </b-form>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from "vuex";
import mapper from "./../mixins/mapper";
import mixins from "@/mixins";

export default {
  name: "UserForm",
  mixins: [mapper, mixins],
  props: {
    propUser: {
      type: Object | null,
      required: true
    }
  },
  data() {
    return {
      errors: {},
      submitting: false,
      form: {
        id: null,
        email: "",
        phone: "",
        role_id: null,
        financial_models: []
      }
    };
  },
  computed: {
    ...mapGetters({
      roles: "user/roles",
      financialModels: "financialModels/getAllFinancialModels"
    })
  },
  methods: {
    onSubmit() {
      this.submitting = true;
      this.$store
        .dispatch(
          this.propUser === null ? "user/createUser" : "user/updateUser",
          this.getChangedFields(this.$refs.formValidationUser.fields, this.form)
        )
        .then(() => {
          this.$root.$emit("bv::hide::modal", "modalUser");
        })
        .catch(({ response }) => {
          this.submitting = false;
          this.$snotify.error("Помилка створення/оновлення користувача");
          this.errors = {};
          for (let variable in response.data.description) {
            this.errors[variable] = response.data.description[variable];
          }

          this.$refs.formValidationUser.setErrors(this.errors);
        });
    }
  },
  watch: {
    propUser: {
      immediate: true,
      deep: true,
      handler(value) {
        this.form.id = value?.id || null;
        this.form.email = value?.email || "";
        this.form.phone = value?.phone || "";
        this.form.role_id = value?.role?.id || null;
        this.form.financial_models =
          value?.financial_models.map(e => e.id) || [];

        requestAnimationFrame(() => {
          this.$refs.formValidationUser.reset();
        });
      }
    }
  }
};
</script>
